import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { WishListCard } from '../components/common';
import CartAction from '../redux/actions/CartAction';
import CardProduct from '../components/common/Cardproduct';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
const Wishlist = () => {
    const { deleteCart } = CartAction();

    const dispatch = useDispatch()

    const cartDetail = useSelector(state => state.cartdetail.cartDetail);

    const wishlistdata = cartDetail.result.filter(ele1 => ele1.cart_status == 2);
    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteCart(input_data))
    }
    return (
        <div>
            <Container fluid>
                <Helmet>
                    <title>{localStorage.getItem("title")} - WishList</title>
                    <link rel="canonical" href={window.location.origin + window.location.pathname} />
                    {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
                </Helmet>
                {
                    cartDetail.result.length > 0 ?
                        <div>
                            <div className='wishlist-maintitle mt-2'>My Wishlist <span className='mywishlist-item-count'> ({cartDetail.result.filter(ele => ele.cart_status == 2).length}  items)</span></div>
                            <Row className='mt-2' >
                                <div className='infinite-scroll-component__outerdiv'>
                                    <div className='infinite-scroll-component wishlist-main-div '>

                                        {
                                            wishlistdata && wishlistdata.length > 0 &&
                                            wishlistdata.map(ele => {
                
                                                return (
                                                    <Col lg={2} md={4} sm={4} xs={6} className="product-list wishlist-col">
                                                        {/* <WishListCard ele={ele} /> */}

                                                        <CardProduct wishlist={true} image={ele.img} title={ele.product_title} rating={ele.rating} slug={ele.slug} status={ele.type} price={ele.price} discount={ele.discount_percentage} productid={ele.id} mrp={ele.mrp} />
                                                    </Col>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Row>
                        </div> : <div className='custom-height'></div>
                }




































            </Container>
        </div>
    );
};

export default Wishlist;