
import React from "react";
import Contactformaddress from "../components/common/Contactformaddress";
const Pricing = () => {
    return (
        <div>
       
            <Contactformaddress />
        </div>

    )
}
export default Pricing;