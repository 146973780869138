import React, { useContext, useEffect, useState } from 'react';
import { BsTruck } from "react-icons/bs";
import { FaStore } from "react-icons/fa6";
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import { CheckoutTabs, CHeckoutAddress, CartPaymentMethod, CheckotDeliverySlot, PickupAddressList, CHeckoutPaymentMethod, MobileCheckout, AccountInfo } from '../components/common';
import { useSelector, useDispatch } from 'react-redux';
import MasterAction from '../redux/actions/MasterAction';
import { Helmet } from 'react-helmet';
const Checkout = () => {
    const dispatch = useDispatch()
    const { getProfileData } = MasterAction()
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const [checked, setchecked] = useState(1)
    const masterData = useSelector(state => state.masterdata.masterData);
    const profileData = useSelector(state => state.masterdata.profileData);
    const userData = useSelector(state => state.masterdata.userData);

    const handleClick = (id) => {
        setchecked(id)
    }

    useEffect(() => {
        dispatch(getProfileData())
    }, [])
    return (
        <>
            <Helmet>
                <title>{localStorage.getItem("title")} - Checkout</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            {
                isDesktoporMobile ?
                    <div>

                        <MobileCheckout />
                    </div>
                    :
                    <Container >
                        <Row >
                            <Col lg={7} className="mt-4">
                                {Object.keys(masterData).length > 0 && masterData.vendor.hasOwnProperty("pickup") &&
                                    <div className='delivery-options'>
                                        <div className={`delivery_options_tab ${checked == 1 ? "active_delivery_point" : ""}`} onClick={() => handleClick(1)}><BsTruck className={`${checked == 1 ? "active_delivery_point_icon" : ""}`} /><div className={`${checked == 1 ? "active_delivery_point_text" : ""}`}>Delivery</div></div>

                                        <div className={`delivery_options_tab ${checked == 2 ? "active_delivery_point" : ""}`} onClick={() => handleClick(2)}><FaStore className={`${checked == 2 ? "active_delivery_point_icon" : ""}`} /><div className={`${checked == 2 ? "active_delivery_point_text" : ""}`}>store pickup</div></div>
                                    </div>
                                }

                                {
                                    checked == 1 ?
                                        <>
                                            {
                                                profileData.length > 0 && (profileData[0].mailid == 'null' || profileData[0].mailid == '')
                                                &&
                                                (
                                                    <CheckoutTabs id={5} title={"Add / Edit email"} info={<AccountInfo />} />
                                                )

                                            }



                                            <CheckoutTabs id={1} title={"Delivery Address"} info={<CHeckoutAddress type={1} />} />
                                        </>

                                        :

                                        <CheckoutTabs id={1} title={"Store Pickup"} info={<PickupAddressList type={1} />} />
                                }
                                {
                                    Object.keys(masterData).length > 0 && masterData.vendor.hasOwnProperty("delivery") &&
                                    <CheckoutTabs id={4} title={checked == 1 ? "Select Delivery Slot" : "Select Pickup Slot"} info={<CheckotDeliverySlot type={4} />} />
                                }
                                {
                                    (userData.hasOwnProperty("cust_type") && userData.cust_type == 2) &&
                                    <CheckoutTabs id={3} title={"Billing Address"} info={<CHeckoutPaymentMethod type={3} />} />
                                }


                            </Col>
                            <Col lg={5} className="checkout-cart-paymentmethod">
                                <CartPaymentMethod screentype={"checkout"} />
                            </Col>
                        </Row>
                    </Container>

            }
        </>

    );
};

export default Checkout;