import React, { useEffect, useState } from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { InputBox } from '../components/common';
import { Link } from 'react-router-dom';
import logo from '../images/2.png';
import validate from "../validations/FormValidationRules";
import useFormvalidation from '../validations/FormValidation'
import AuthAction from '../redux/actions/AuthAction';

import OrderAction from '../redux/actions/OrderAction';
import { useSelector, useDispatch } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../components/firebase/Setup';
import { Helmet } from 'react-helmet';
import MyAccountAction from '../redux/actions/MyAccountAction';
import Apis from '../services/Apis';
const Register = () => {
    const dispatch = useDispatch()
    const { verifyotpServiceNew } = Apis()

    const [currentStep, setcurrentStep] = useState(0);
    console.log("currentStep", currentStep)
    const [buttonstatus, setButtonstatus] = useState(false);
    const [user, setuser] = useState(null);
    const verification_status = useSelector(state => state.orderdetail.verification_status);
    const emailotp = useSelector(state => state.orderdetail.emailotp);
    const [formValues, setFormValues] = React.useState(

        localStorage.getItem("verification_method") == 1 ? {
            "name": "",
            "phone": "",
            "password": "",
            "otp": "",
            "category": 1
        }
            :
            {
                "name": "",
                "mailid": "",
                "password": "",
                "otp": "",
                "category": 1
            }




    );

    const { SignUp } = AuthAction();
    const { checkemail } = OrderAction();
    const { values, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );


    async function awer() {
        if (currentStep == 0) {
            setButtonstatus(true)
            const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {});
            const phoneNumberWithCode = `+91${values.phone}`;
            const confirmation = await signInWithPhoneNumber(auth, phoneNumberWithCode, recaptchaVerifier);
            setcurrentStep(1)
            setuser(confirmation)
            setButtonstatus(false)
        } else {
            if (user) {
                try {
                    var confirmationResult = await user.confirm(values.otp);
                    if (confirmationResult) {
                        dispatch(SignUp(values, setErrors, setcurrentStep))
                        // setOtpVerified(true); 
                        // setEnter(false)
                    } else {
                        // setOtpVerified(false); 
                        // setEnter(true)
                        alert("OTP not verified");
                    }
                } catch (err) {
                    if (err.code === "auth/invalid-verification-code") {
                        setErrors({
                            otp: "Please enter a valid OTP."
                        })
                        // Handle invalid OTP
                    }
                }


            }
        }
    }

    async function callEmailVerification() {
        console.log("ddddd");
        if (currentStep == 0) {
            setButtonstatus(true)
            setcurrentStep(1)
            setButtonstatus(false)
        } else {
            console.log("values", values);
            try {
                var confirmationResult = await verifyotpServiceNew(values);
                if (confirmationResult) {
                    // dispatch(SignUp(values, setErrors, setcurrentStep))
                    // setOtpVerified(true); 
                    // setEnter(false)
                } else {
                    // setOtpVerified(false); 
                    // setEnter(true)
                    alert("OTP not verified");
                }
            } catch (err) {
                if (err.code === "auth/invalid-verification-code") {
                    setErrors({
                        otp: "Please enter a valid OTP."
                    })
                    // Handle invalid OTP
                }
            }


        }
    }

    useEffect(() => {
        console.log("verification_status");

        if (verification_status != undefined) {
            if (Object.keys(verification_status).length > 0) {
                setErrors({
                    phone: verification_status.message
                })
            } else {
                if (localStorage.getItem("verification_method") == 1) {
                    awer()
                } else {
                    callEmailVerification()
                }
            }

        }

    }, [verification_status])

    async function confirmOtp(input_data1) {
        const response = await verifyotpServiceNew(input_data1)
        if (response.data.status == 'error') {
            setErrors({
                otp: "Please enter a valid OTP."
            })
        } else {
            dispatch(SignUp(values, setErrors, setcurrentStep))
        }
    }
    async function handleNext() {
        var input_data = {
            email: values.mailid,
            phone: values.phone
        }
        if (currentStep == 0) {
            console.log("vochag");
            dispatch(checkemail(input_data))
        } else {
            console.log("miigfdxfghjhbgv");
            console.log("verification_method", localStorage.getItem("verification_method"));
            if (localStorage.getItem("verification_method") != 1) {
                var input_data1 = {
                    email: values.mailid,
                    otp: values.otp,
                    id: emailotp.data,
                }
                confirmOtp(input_data1)
            } else {
                awer()
            }
        }
    }
    
    return (
        <Container>
            <Helmet>
                <title>{localStorage.getItem("title") + " - " + "Register"}</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            <div className="register-container">
                <div className="register-wrap-container">
                    <div lg={5} className="right-side">
                        <h4 className="mb-4">Please Register Here </h4>
                        <Row>
                            <Col lg={6}>
                                <InputBox currentStep={currentStep} label={"Name"} id={"name"} handleChange={handleChange} errors={errors} />
                            </Col>
                            {
                                localStorage.getItem("verification_method") == 1 ?
                                    <Col lg={6}>
                                        <InputBox currentStep={currentStep} label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    :
                                    <Col lg={6}>
                                        <InputBox currentStep={currentStep} label={"Email"} id={"mailid"} handleChange={handleChange} errors={errors} />
                                    </Col>
                            }


                            <Col lg={6}>
                                <InputBox currentStep={currentStep} label={"Password"} id={"password"} handleChange={handleChange} errors={errors} />
                            </Col>

                            {
                                currentStep == 1 &&
                                <Col lg={6}>
                                    <InputBox label={"Enter Otp"} id={"otp"} handleChange={handleChange} errors={errors} />
                                </Col>


                            }
                            {/* {
                               emailotp == "OTP send successfully" &&
                                <Col lg={6}>
                                    <InputBox label={"Enter Otp"} id={"otp"} handleChange={handleChange} errors={errors} />
                                </Col>


                            } */}

                            {
                                currentStep != 1 && localStorage.getItem("verification_method") == 1 &&
                                <Col lg={12} className='captcha-div'>

                                    <div id="recaptcha"></div>
                                </Col>
                            }

                        </Row>





                        <div className={`product-detail-buttongroup normal-register-btn ${buttonstatus ? "disable" : " "}`} >
                            <div className={`product-detail-button primaryColor`} onClick={handleSubmit}>

                                {currentStep == 0 ? "Get Otp" : "Verify Otp"}


                            </div>
                        </div>

                        <div className='signupsec'>
                            <span className='DontAccount'>Already have an account, please</span>
                            <Link to="/login" className='signUpNavLink primaryColorcolor text-underline-none'>Sign In</Link>
                        </div>
                        <div className='signupsec navlink-2'>
                            <span className='DontAccount'>Buying for Business</span>
                            <Link to="/buisness-register" className='signUpNavLink primaryColorcolor text-underline-none'>Create a business account</Link>
                        </div>

                    </div>
                </div>

            </div>
        </Container>



    );
};

export default Register;