import React, { useContext, useState, useEffect } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useMediaQuery } from 'react-responsive';
import { HomeCarousel, HomeCard, HomeCarouselCardComponent, Twobanner, Bannercategories, Homethreebanner, BackgroundimagCarousel, SingleCarousel, Blogs, Clientsdata, Ourproductcomp, HomepageBrands, FourBanner, HomepageCarousel, ImageCard, Banner41, MobileOnecard8banner, ThreeCategoryBanners, Banner1, Banner3, Banner2, HomeComponenet4, Banner8, Brand3, MultipleTabComponenet, GoogleMap, HomeCategorySlider, Brand4, About, ContactForm, SelectedCategory } from '../components/common';
import { IoMdArrowDropdown } from "react-icons/io";
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import logo from '../images/bg_image.webp';
// import Banner2 from '../components/common/Banner2';
const Home = React.memo(() => {
    const isDesktopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const isDesktopOrMobile1 = useMediaQuery({ query: '(min-width: 768px)' })
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const HomePageData = useSelector(state => state.masterdata.HomePageData);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [HomePageData])

    const [selectedData, setSelectedData] = useState(null);
    const [selectedButton, setSelectedButton] = useState(0);
    const [selectedopen, setSelectedopen] = useState(true);
    const handleClick = (index, item) => {
        const clickedData = HomePageData[0].sectionList.filter(comp => comp.component === 'full-carousel-products').find((item, idx) => idx === index);
        setSelectedData(clickedData);
        setSelectedButton(index);
        setSelectedOption(item);
        setSelectedopen(false)
    };
    var comps = [];
    const [selectedOption, setSelectedOption] = useState();


    useEffect(() => {
        // Set initial selected data if needed
        if (HomePageData.length > 0) {
            var filteredData = HomePageData[0].sectionList.filter(comp => comp.component === 'full-carousel-products');
            setSelectedData(filteredData.length > 0 ? filteredData[0] : null);
            setSelectedOption(comps[0])
        }
    }, [HomePageData]);
    return (
        <div className='homepage-top-space'   >
            <Container className='home-custom-container'>
                <Helmet>
                    <title>{localStorage.getItem("title")} - {localStorage.getItem("domain_title")} </title>
                    {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
                </Helmet>


                {
                    HomePageData.length > 0 ?
                        <>
                            {


                                HomePageData[0].sectionList.map((comp, index) => {
                                    // if (comp.component == 'category-component') {
                                    //     return (
                                    //         <div className='pt-3'>
                                    //             <HomeCategorySlider categoryData={comp.sectionResult}/>
                                    //         </div>

                                    //     )
                                    // }
                                    // if (comp.component == 'main-slider') {
                                    //     return (
                                    //         <HomeCarousel data={comp} key={index} />
                                    //     )
                                    // }

                                })
                            }
                            <div className={!isDesktopOrMobile1 ? "" : "custom-card-main"}>
                                <div className="home-card-component">
                                    {
                                        HomePageData.length > 0 &&

                                        HomePageData[0].sectionList.map((comp, index) => {
                                            // if (comp.component == 'card-component') {
                                            //     return (
                                            //         <Row key={index}>
                                            //             {
                                            //                 comp.sectionResult.map((ele, subindex) => {
                                            //                     return (

                                            //                         ele.result_type == 'static' ?
                                            //                             <Col xl={6} lg={6} md={6} key={subindex}>
                                            //                                 <div className='home-halfbanner'>
                                            //                                     <img referrerPolicy="no-referrer" src={ele.card_image}
                                            //                                         onError={e => {
                                            //                                             e.currentTarget.src = logo
                                            //                                         }} />
                                            //                                 </div>
                                            //                             </Col>

                                            //                             :

                                            //                             <Col xl={3} lg={3} md={6} sm={6} key={subindex}>
                                            //                                 <HomeCard data={ele} />
                                            //                             </Col>
                                            //                     )
                                            //                 })
                                            //             }
                                            //         </Row>
                                            //     )
                                            // }
                                            // if (comp.component == 'full-banner') {
                                            //     var bannerimage = comp.sectionResult[0].image;
                                            //     var mobileimage = comp.sectionResult[0].mobile_image;
                                            //     return (
                                            //         <div className='home-fullbanner mb-2 ' key={index}>
                                            //             {
                                            //                 isMobile ?
                                            //                     <img referrerPolicy="no-referrer" src={mobileimage} onError={e => {
                                            //                         e.currentTarget.src = logo
                                            //                     }} /> :
                                            //                     <img referrerPolicy="no-referrer" src={bannerimage} onError={e => {
                                            //                         e.currentTarget.src = logo
                                            //                     }} />
                                            //             }



                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'half-carosal-component') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             {
                                            //                 !isMobile ?
                                            //                     <HomeComponenet4 data={comp.sectionResult} classname={"half_carousel_img"} btn={"onecard"} />
                                            //                     : <MobileOnecard8banner data={comp.sectionResult} classname={"half_carousel_img"} />
                                            //             }

                                            //         </div>
                                            //     )
                                            // }
                                            <div>hi sridhar</div>
                                            // if (comp.component == 'full-carousel-products') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <HomeCarouselCardComponent showbuttons={true} position={1} loopindex={index} carouselCompData={comp} type={"view-all"} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'four-banners') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Brand4 carouselCompData={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'double-banner') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner2 carouselCompData={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'three-banners') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner3 carouselCompData={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'three-banners-V2') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner3 carouselCompDataV2={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'three-banners-V3') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner3 carouselCompDataV3={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // // if (comp.component == 'full-banner') {
                                            // //     return (
                                            // //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            // //             <Banner1 carouselCompData={comp}/>
                                            // //         </div>
                                            // //     )
                                            // // }
                                            // if (comp.component == 'four-banners-v2') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner41 carouselCompData={comp} />
                                            //         </div>
                                            //     )
                                            // }

                                            // if (comp.component == 'leftimg-rightcont-component') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <About data={comp.sectionResult[0]} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'leftcont-rightimg-component') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <ImageCard data={comp.sectionResult[0]} />
                                            //         </div>
                                            //     )
                                            // }

                                            // .................................................Home slider
                                            if (comp.component == 'main-slider') {
                                                return (
                                                    <div className='homepage-components'>
                                                        {
                                                            comp &&

                                                            <HomepageCarousel slider_data={comp} key={index} position={0} />
                                                        }
                                                    </div>
                                                )
                                            }
                                            //  .................................................threeBanner
                                            if (comp.component == 'threeBanner-component') {
                                                return (
                                                    <Container>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&

                                                                <Homethreebanner data={comp.sectionResult.slice(0, 3)} />
                                                            }
                                                        </div>
                                                        {/* <div className='space-30'></div> */}
                                                    </Container>
                                                )
                                            }
                                            // ......................................Carousel with background images
                                            if (comp.component == 'carousel-with-background-image') {
                                                return (
                                                    <div className='homepage-components'>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <BackgroundimagCarousel backgrounddata={comp.sectionResult[0]} position={1} />
                                                        }
                                                    </div>
                                                )
                                            }
                                            // ..................................................two Banners
                                            if (comp.component == 'twoBanner-component') {
                                                return (
                                                    <div className='homepage-components'>
                                                        {
                                                            comp.sectionResult.length > 0 &&
                                                            <Twobanner data={comp.sectionResult.slice(0, 2)} />
                                                        }

                                                    </div>
                                                )
                                            }
                                            // .................................................Full Carousel Products
                                            if (comp.component == 'full-carousel-products') {
                                                return (
                                                    <div className='homepage-components'>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <SingleCarousel single={true} fullcarouseldata={comp.sectionResult} heading={comp.section_title} position={3} description={comp.description} />
                                                        }
                                                    </div>
                                                )
                                            }
                                            // .................................................Full Carousel Blogs
                                            if (comp.component == 'full-carousel-Blogs') {
                                                return (
                                                    <Container className='space-bottom-20'>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&
                                                                <Blogs position={5} Blog={true} fullcarouselBlogdata={comp.sectionResult} Blogheading={comp.section_title} blogdiscription={comp.description} />
                                                            }

                                                        </div>
                                                    </Container>
                                                )
                                            }
                                            // ..................................................testmonial
                                            if (comp.component == 'testmonial') {
                                                return (
                                                    <>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&

                                                                <Clientsdata
                                                                    haedingdata={comp.sectionResult[0]}
                                                                    sliderdata={comp.sectionResult && comp.sectionResult.length > 0 ? comp.sectionResult.slice(1) : []}
                                                                />
                                                            }
                                                        </div>
                                                        <div className='space-30'></div>
                                                    </>
                                                )
                                            }


                                            // .......................................................categorycomp
                                            if (comp.component == 'category-component') {
                                                return (
                                                    <Container>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&

                                                                <SingleCarousel categorydata={comp.sectionResult} position={4} add={true} />
                                                            }
                                                        </div>
                                                    </Container>
                                                )
                                            }
                                            //..................................................................Multiple Full Carousel

                                            // <div className='space-30'></div>

                                            if (comp.component == 'multiple-full-carousel') {
                                                return (
                                                    <Container>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&

                                                                <Ourproductcomp multiplefullcomp={comp.sectionResult} />
                                                            }
                                                        </div>
                                                    </Container>
                                                )
                                            }
                                            <div className='space-30'></div>
                                            //......................................................................Brands
                                            if (comp.component == 'Selected-category') {
                                                return (
                                                    <Container>
                                                        <div className='space-30'></div>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&

                                                                <HomepageBrands position={2} Brandsdata={comp.sectionResult} />
                                                            }
                                                        </div>
                                                        <div className='space-30'></div>
                                                    </Container>
                                                )
                                            }
                                            //......................................................................single Banner
                                            if (comp.component == 'singlebanner-component') {
                                                return (
                                                    <div className='homepage-components'>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <Banner1 singlebannerdata={comp.sectionResult[0]} />
                                                        }
                                                    </div>
                                                )
                                            }
                                            //......................................................................four Banner
                                            if (comp.component == 'fourBanner-component') {
                                                return (
                                                    <div className={`space-20 space-30`}>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <FourBanner fourbannerdata={comp.sectionResult} />
                                                        }
                                                    </div>
                                                )
                                            }
                                            //.....................................................................Five banner category
                                            if (comp.component == 'Banner-category') {
                                                return (
                                                    <div className={`space-20 space-30`}>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <Bannercategories bannercatdata={comp.sectionResult} />

                                                        }<div className={` space-30 space-20`}>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <div className='space-30'></div>
                                        })
                                    }
                                    {/* <SelectedCategory/>
                                            <ThreeCategoryBanners/> */}
                                    {
                                        isDesktopOrMobile ? HomePageData[0].sectionList.map((comp, index) => {
                                            if (comp.component == 'full-carousel-products-tab') {
                                                return (
                                                    <div className='home-carousel-bg-wihte mb-2' key={index}>
                                                        {/* <Homemobile position={1} carouselCompData={comp} type={"view-all"} /> */}
                                                    </div>
                                                )
                                            }

                                        })


                                            :


                                            HomePageData.length > 0 &&


                                                HomePageData[0].sectionList.filter(comp => comp.component === 'full-carousel-products-tab').length > 0 ?

                                                <div className='home-carousel-bg-wihte mb-2'>
                                                    <Row >
                                                        <Col lg={12} md={12} sm={12}>
                                                            <div className='ourproducts'>
                                                                <div className='Quality-products'>
                                                                    <h5 className='Quality-products-details-left1'>

                                                                    </h5>
                                                                    <h5 className='Quality-products-details-left2'>
                                                                        Our Products
                                                                    </h5>
                                                                </div>

                                                                <div className='ourproduct-title-types'>
                                                                    {
                                                                        isDesktopOrMobile ?
                                                                            // <>
                                                                            //     <NavDropdown title={selectedOption || comps[0]} className='ourproduct-details-dropdown-maintitle' >
                                                                            //         {
                                                                            //             HomePageData.length > 0 &&
                                                                            //             HomePageData[0].sectionList.filter(comp => comp.component === 'full-carousel-products')
                                                                            //                 .map((comp, index) => {
                                                                            //                     comps.push(comp.section_title);
                                                                            //                     return (
                                                                            //                         <div key={index}>
                                                                            //                             <NavDropdown.Item className='ourproduct-details-dropdown'

                                                                            //                             >
                                                                            //                                 <h5 className='ourproduct-title-types-titles' onClick={() => handleClick(index, comp.section_title)} style={{ color: selectedButton === index ? "orange" : 'black' }}>{comp.section_title}</h5>


                                                                            //                             </NavDropdown.Item>


                                                                            //                         </div>
                                                                            //                     )

                                                                            //                 })

                                                                            //         }
                                                                            //     </NavDropdown>



                                                                            // </>


                                                                            <></>











                                                                            :
                                                                            HomePageData.length > 0 &&
                                                                            HomePageData[0].sectionList.filter(comp => comp.component === 'full-carousel-products').map((comp, index) => {

                                                                                return (
                                                                                    <div key={index}>

                                                                                        <h5 className='ourproduct-title-types-titles' onClick={() => handleClick(index, comp.section_title)} style={{ color: selectedButton === index ? 'orange' : 'black' }}>{comp.section_title}</h5>

                                                                                    </div>

                                                                                )

                                                                            })
                                                                    }
                                                                </div>

                                                            </div>

                                                        </Col>
                                                        {
                                                            selectedData &&
                                                            <MultipleTabComponenet key={selectedButton} position={1} carouselCompData={selectedData} link={"product-detail"} />
                                                        }

                                                    </Row>


                                                </div> : " "
                                    }



                                    {/* <div><Brand3/></div>
                                    <div><Banner8/></div>
                                    <div><About/></div>
                                    <div><MapAddress/></div> 
                                    <div><GoogleMap/></div>  */}
                                    {/* <div><ContactForm/></div> */}
                                </div>

                            </div>


                        </>
                        :
                        <div className='custom-height'></div>
                }

            </Container>
        </div>
        // <HomePlaceholder />
    );
});

export default Home;


