import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Modal from './Modal';
const Banner8 = ({data}) => {
    const dispatch =useDispatch();
    const brands = [
        {
            image: "https://htmldemo.net/makali/makali/img/banner/cosmetic/1-4.webp",
            title: "Autumn",
            url: "autumn-1014101"
        },
        {
            image: "https://htmldemo.net/makali/makali/img/banner/cosmetic/1-5.webp",
            title: "Pink pastel Package",
            url:"pink-pastel-package-1015101"
        }
    ]
    const opencontct=()=>{
        dispatch({
            type:"setopencontact",
            payload:true
        })
    }
    // const [our1, our2, our3, our4] = brands;
    return (
        <div>
            <Container fluid>
             
                <Row className='top-space'>
                    {
                         data && data.length >0 &&
                        data.map(ele => {
                            return (
                                <Col lg={6} md={6} sm={6} xs={12} className='brands-col-maindivs'>
                                    {/* <NavLink to={`${ele.url}`}> */}

                                        <div className='single-banners' onClick={opencontct}>
                                            <img
                                                referrerPolicy="no-referrer"

                                                className='brand-imagess' src={ele.card_image}

                                            ></img>

                                        </div>
                                    {/* </NavLink> */}
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            <Modal/>
        </div>
    );
};


export default Banner8;









