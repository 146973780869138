import React, { useContext, useEffect } from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { InputBox } from '../components/common';
import { Link } from 'react-router-dom';
import validate from "../validations/FormValidationRules";
import useFormvalidation from '../validations/FormValidation'
import AuthAction from '../redux/actions/AuthAction';
import { useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
const Login = () => {
    const dispatch = useDispatch()
    const [formValues, setFormValues] = React.useState(
        // email: "",
        // password: "",

        localStorage.getItem("verification_method") == 1 ? {

            "phone": "",
            "password": "",
            ...(localStorage.getItem("sessionId") != null && { session_id: localStorage.getItem("sessionId") })
        }
            :
            {
                "email": "",
                "password": "",
                ...(localStorage.getItem("sessionId") != null && { session_id: localStorage.getItem("sessionId") })
            }

    );
    const { LoginApi } = AuthAction();
    const { values, errors, handleChange, setErrors, handleSubmit } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );
    function handleNext() {
        dispatch(LoginApi(values, errors, setErrors))
    }


    return (
        <Container>
            <Helmet>
                <title>{localStorage.getItem("title")} - login</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            <div className="register-container login-container">
                <div className="register-wrap-container">
                    <div lg={5} className="right-side">
                        <h4 className="mb-4">Please Sigin Here </h4>
                        {
                            localStorage.getItem("verification_method") == 1 ?
                                <Col lg={12}>
                                    <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
                                </Col>
                                :
                                <Col lg={12}>
                                    <InputBox label={"Email"} id={"email"} handleChange={handleChange} errors={errors} />
                                </Col>
                        }
                                    <InputBox label={"Password"} id={"password"} handleChange={handleChange} errors={errors} />

                        <div className='product-detail-buttongroup login-register-btn' onClick={handleSubmit}>
                            <div className={`product-detail-button primaryColor secondaryColorHover`}>
                                Login
                            </div>
                        </div>
                        <div className='signupsec'>
                            <span className='DontAccount'>Forgot your password ? </span>
                            <Link to="/password-reset" className='signUpNavLink primaryColorcolor text-underline-none2'>click here</Link>
                        </div>
                        <div className='signupsec'>
                            <span className='DontAccount'>Dont have an account, please</span>
                            <Link to="/register" className='signUpNavLink primaryColorcolor text-underline-none'>Register</Link>
                        </div>
                        <div className='signupsec navlink-2'>
                            <span className='DontAccount'>Buying for Business</span>
                            <Link to="/buisness-register" className='signUpNavLink primaryColorcolor text-underline-none'>Create a business account</Link>
                        </div>
                    </div>
                </div>

            </div>
        </Container>



    );
};

export default Login;