import React from 'react';
import { FaUser } from "react-icons/fa";
import { GiTimeBomb } from "react-icons/gi";
import { FaPlus } from "react-icons/fa6";
import logo from '../../images/bg_image.webp';
const BlogPosts = ({ image, discription, author_name, category_name, published_date, content, title }) => {
    return (
        <div>
            <div className='images-hover'>
                <div className='images-hover-sub-div'>
                    <div className='Singleimage-main-image-div'>
                        <img referrerPolicy="no-referrer" className='Singleimage-image' src={image}
                         onError={e => {
                            e.currentTarget.src = logo
                        }}></img>
                        <div className={`imagecard-absolute imagecard-side  Singleimage-main-image-div-images `}>
                            {
                                category_name &&
                                <div className='ImageCard-category_name small-comp-image-author'><div className='category_name'>{category_name}</div></div>
                            }

                        </div>
                    </div>

                </div>
            </div>
            <h5 className={`Singleimage-image-title `}>
                {title}
            </h5>
            {
                
                <div className='Singleimage-maindiv '>


                    <div className='Singleimage-author Singleimage-1 primaryColorcolor '><FaUser className='Singleimage-author-icon' /> {author_name}</div>


                    <div className='Singleimage-author primaryColorcolor '><GiTimeBomb className='Singleimage-author-icon' /> {published_date} </div>


                </div>
            }
            <h5 className={`Singleimage-image-discription `}>
                {discription}
            </h5>
       <div className='singleimage-button primaryColorHover'>
        <FaPlus/> Read More
       </div>
        </div>

    );
};


export default BlogPosts;