import { useSelector } from "react-redux";
import Apis from "../../services/Apis";
import Cookies from 'universal-cookie';

const SearchAction = () => {
  const productcatFiltersData = useSelector(state => state.catlogdetail.productcatFiltersData);

  const { getSubcategorydataService, SearchProductService, SearchResultService, getFilterSearchResultApi } = Apis();

  var cookies = new Cookies();

  const getSubcategorydata = (input_data) => async dispatch => {
  // alert()
    dispatch({
      type: 'setloader',
      payload: true
    });
    // if (input_data.page == 1) {
   
    // }
    const response = await getSubcategorydataService(input_data);
   
    // alert()
    if (response.data.status == "success") {

      dispatch({
        type: 'setloader',
        payload: false
      });
     
      if (Object.keys(response.data.data).length > 0) {
        dispatch({
          type: 'subfiltertotaldata',
          payload: {
            filters: response.data.data.total_result
          }
        });
       
          if (input_data.type == 'filtered') {
            dispatch({
              type: 'subcategory_data',
              payload: {
                filters: response.data.data.filters
              }
            });
            dispatch({
              type: 'setloader',
              payload: true
            });
          } else {
            dispatch({
              type: 'setloader',
              payload: false
            });
            dispatch({
              type: 'subcategory_data',
              payload: {
                filters: response.data.data.filters
              }
            });
            dispatch({
              type: 'setproductcatFiltersData',
              payload: {
                display_items: response.data.data.display_items
              }
            });
          }
    
      } else {
        if (input_data.page == 1) {
          dispatch({
            type: 'subcategory_data',
            payload: { result: "nodata" }
          });
        }
        dispatch({
          type: 'SethasMore',
          payload: false
        });
      }
      dispatch({
        type: 'setloader',
        payload: false
      });
    } else {
      dispatch({
        type: 'subcategory_data',
        payload: { result: "nodata" }
      });
      dispatch({
        type: 'setloader',
        payload: true
      });
    }
  };

  const getFilterSearchResult = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await getFilterSearchResultApi(input_data);
    if (response.data.status == "success") {
      dispatch({
        type: 'subfiltertotaldata',
        payload: {
          filters: response.data.data.total_result
        }
      });
      // dispatch({ type: 'setFilterPage', payload: 1 })
      if (Object.keys(response.data.data).length > 0) {
        if (input_data.page == 1) {
          dispatch({
            type: 'setproductcatFiltersData',
            payload: {
              display_items: response.data.data.display_items
            }
          });
        } else {
          // dispatch({
          //   type: 'setproductcatFiltersData',
          //   payload:  {
          //     display_items: {
          //       ...productcatFiltersData["display_items"],
          //       ["products"]: [
          //         ...productcatFiltersData["display_items"]["products"],
          //         ...response.data.data.display_items.products,
          //       ],
          //     },
          //   }
          // });
          dispatch({
            type: 'setproductcatFiltersData',
            payload: {
              display_items: response.data.data.display_items
            }
          });
        }
        // if (input_data.page == 1) {
        //   setLoaderstatus(false)
        // }
      } else {
        if (input_data.page == 1) {
          dispatch({
            type: 'subcategory_data',
            payload: { result: "nodata" }
          });
        }
        dispatch({
          type: 'SethasMore',
          payload: false
        });
        // setLoaderstatus(false)
      }

      dispatch({
        type: 'setloader',
        payload: false
      });

    } else {
      dispatch({
        type: 'subcategory_data',
        payload: { result: "nodata" }
      });
      dispatch({
        type: 'SethasMore',
        payload: false
      });
      // setLoaderstatus(false)
    }
  };

  const getSearchProduct = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await SearchProductService(input_data);
    if (response.data.status == "success") {
      dispatch({
        type: 'setshowdata',
        payload: true
      });
      dispatch({
        type: 'setsearchDetail',
        payload: response.data.data.product
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    } else {
      dispatch({
        type: 'setsearchDetail',
        payload: []
      });
      dispatch({
        type: 'setshowdata',
        payload: false
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };

  const getSearchResult = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await SearchResultService(input_data);
    if (response.data.status == "success") {
      if (Object.keys(response.data.data).length > 0) {
        if (input_data.page == 1) {
          if (input_data.type == 'filtered') {
            dispatch({
              type: 'subcategory_data',
              payload: {
                filters: response.data.data.filters
              }
            });
          } else {
            dispatch({
              type: 'subcategory_data',
              payload: {
                filters: response.data.data.filters
              }
            });
            dispatch({
              type: 'setproductcatFiltersData',
              payload: {
                display_items: response.data.data.display_items
              }
            });
          }

        } else {
          dispatch({
            type: 'setproductcatFiltersData',
            payload: {}
          });
          dispatch({
            type: 'setproductcatFiltersData',
            payload: {
              ...productcatFiltersData,
              display_items: {
                ...productcatFiltersData["display_items"],
                ["products"]: [
                  ...productcatFiltersData["display_items"]["products"],
                  ...response.data.data.display_items.products,
                ],
              },
            }
          });
        }
      } else {
        dispatch({
          type: 'SethasMore',
          payload: false
        });
      }
      dispatch({
        type: 'setloader',
        payload: false
      });
    } else {
      dispatch({
        type: 'subcategory_data',
        payload: { result: "nodata" }
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };

  return {
    getSubcategorydata: getSubcategorydata,
    getFilterSearchResult: getFilterSearchResult,
    getSearchProduct: getSearchProduct,
    getSearchResult: getSearchResult
  }
};

export default SearchAction;
