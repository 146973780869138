import { useSelector } from "react-redux";
import Apis from "../../services/Apis";
import Cookies from 'universal-cookie';
import { notify } from "../../components/common";

const ProductAction = () => {
  const { getProductDetailService, SaveRecentlyViewedApi,getRelatedItemsApi,saveNewsletterdat,saveContactFormdata } = Apis();

  var cookies = new Cookies();

  const getProductDetail = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await getProductDetailService(input_data);
    if (response.data.status == "success") {
      dispatch({
        type: 'setproductDetailData',
        payload:  response.data.productList
      });
      dispatch({
        type: 'setProductdetailimage',
        payload:  response.data.productList[0].product_images[0]
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    } else {
      dispatch({
        type: 'setproductDetailData',
        payload: []
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };
  const getRelatedItems = (input_data) => async dispatch => {
   
    const response = await getRelatedItemsApi(input_data);
    if (response.data.status == "success") {
      dispatch({
        type:'setprodDetailBottomSection',
        payload: response.data.data
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    } else {
      dispatch({
        type: 'setprodDetailBottomSection',
        payload: []
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };
  const SaveRecentlyViewed = (input_data) => async dispatch => {
    await SaveRecentlyViewedApi(input_data);
  };
  const saveNewsLetterdatalist = (input_data) => async dispatch => {
    const response = await saveNewsletterdat(input_data);
    if (response.data.status == "success") {
      dispatch({
        type:'setprodDetailBottomSection',
        payload: response.data.data
      });
      notify('add2cart', response.data.mesaage, false)
    } else {
      dispatch({
        type: 'setprodDetailBottomSection',
        payload: []
      });
      notify('add2cart', response.data.mesaage, false)
    }
  };
  const saveContactFormdatalist = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader1',
      payload: true
    });
    const response = await saveContactFormdata(input_data);
    if (response.data.status == "success") {
      notify('add2cart', response.data.mesaage, false)
      dispatch({
        type: 'setOpenmodal',
        payload: false
    })
      // dispatch({
      //   type:'setprodDetailBottomSection',
      //   payload: response.data.data
      // });
      dispatch({
        type: 'setloader1',
        payload: false
      });
    } else {
      notify('add2cart', response.data.mesaage, false)
      // dispatch({
      //   type: 'setprodDetailBottomSection',
      //   payload: []
      // });
      dispatch({
        type: 'setloader1',
        payload: false
      });
    }
  };

  return {
    getProductDetail:getProductDetail,
     getRelatedItems:getRelatedItems,
     SaveRecentlyViewed:SaveRecentlyViewed,
     saveNewsLetterdatalist:saveNewsLetterdatalist,
     saveContactFormdatalist:saveContactFormdatalist
  }
};

export default ProductAction;
