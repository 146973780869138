import React, { useContext } from 'react';
import { BsExclamationCircleFill,BsArrowRightCircleFill } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux';

const InputBox = ({ label, id, handleChange, disabled, change_btn, errors, value, currentStep,classname,handleSubmit }) => {
    const dispatch = useDispatch()
    const openModal = useSelector(state => state.cartdetail.openModal);

    const updatedProfilephone = {}
    const setotpformType = ''

    const handleClick = (id) => {
        dispatch({
            type: 'setopenModal',
            payload: !openModal
        });
        // setotpformType(id)
    }
    return (
        <>
            {
                disabled ?
                    <>
                        <div className='field-holder'>
                            <input type="text" id={id} name={id} defaultValue={updatedProfilephone.hasOwnProperty(id) ? updatedProfilephone[id] : value} className={`custom-input custom-input-disabled ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} />
                            <label htmlFor={id} className="custom-label primaryColorcolor">{label}</label>
                            {
                                change_btn == undefined &&
                                <div className="custom-change-icon primaryColorcolor" onClick={() => handleClick(id)}>change</div>
                            }

                        </div>
                    </>
                    :
                    <>
                        <div className='field-holder' style={{
                            pointerEvents: currentStep == 1 ? 'none' : '',
                            opacity: currentStep == 1 ? '.5' : '1',
                        }}>
                            <input type={id == 'password' ? 'password' : "text"} id={id} name={id} value={value} className={`custom-input ${classname} ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} required onChange={handleChange} />
                            <label htmlFor={id} className="custom-label primaryColorcolor">{label}</label>
                            {errors.hasOwnProperty(id) && <BsExclamationCircleFill className="error-icon" />}
                            {classname == 'input-bg-black' && <BsArrowRightCircleFill className="subsribe-arrow-icon primaryColorcolor" onClick={handleSubmit}/>}
                        </div>
                        {errors.hasOwnProperty(id) && <span className='error-text'>{errors[id]}</span>}
                    </>
            }

        </>



    );
};

export default InputBox;