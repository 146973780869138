import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import 'swiper/css/pagination';
import logo from '../../images/bg_image.webp';
import { useDispatch } from "react-redux";
import Cardproduct from "./Cardproduct";
import HeadingTitle from "./HeadingTitle";
const Detailsmallcarousel = ({ position, productimages }) => {
    const dispatch =useDispatch();
    const clicks=(ele)=>{
        // alert()
        dispatch({
            type: 'setProductdetailimage',
            payload: ele
          });
    }
 
    return (
        <>
            <div className="swiper-main">

                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={20}
                    effect="cards"
                    // loop={true}
                    className=""
                    navigation={{
                        nextEl: `.nextbtn${position}`,
                        prevEl: `.previousbtn${position}`,
                    }}
                    breakpoints={{
                        200: {
                            slidesPerView: 2,
                        },
                        400: {
                            slidesPerView: 2.5,
                        },
                        700: {
                            slidesPerView: 2,
                        },
                        750: {
                            slidesPerView: 3,
                        },
                        1000: {
                            slidesPerView: 4,
                        },
                        1200: {
                            slidesPerView: 4,
                        },
                        1400: {
                            slidesPerView: 4,
                        },
                        1600: {
                            slidesPerView: 4,
                        },
                        1800: {
                            slidesPerView: 4,
                        },
                    }}
                // className={`mycategorySwiper${1}`}

                >
                    <div>

                        return (
                        {
                            productimages && productimages.length > 0 &&
                            productimages.map(ele => {
                                return (
                                    <SwiperSlide >
                                        <img className="homepagebrands-images detailpage-images image_aspect_ratio" src={ele} onClick={()=>clicks(ele)}
                                         onError={e => {
                                            e.currentTarget.src = logo
                                        }}></img>
                                    </SwiperSlide>
                                )
                            })
                        }
                        )
                    </div>

                </Swiper>
                <div >
                    <div className={`previousbtn primaryColorHover secondaryColor previousbtn${position} `}><BiSolidChevronLeft /></div>
                    <div className={`nextbtn primaryColorHover secondaryColor nextbtn${position} `}><BiSolidChevronRight /></div>
                </div>
            </div>
        </>
    )
}
export default Detailsmallcarousel;