import React, { useEffect } from 'react'
import SingleCarousel from './SingleCarousel'
import { useSelector, useDispatch } from 'react-redux';

const ourproduct = [
    {

        "heading": "product1",
        "id": 1,
        "result": [
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 30,
                "status": "old",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 12,
                "status": "old",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 300,
                "status": "old",
                "discount": "30",
                "rating": 5
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 300000,
                "status": "old",
                "discount": "30",
                "rating": 4
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 344440,
                "status": "old",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 30,
                "status": "old",
                "discount": "30",
                "rating": 1
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 12,
                "status": "old",
                "discount": "30",
                "rating": 3
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 300,
                "status": "old",
                "discount": "30",
                "rating": 5
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 300000,
                "status": "old",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/6.webp",
                "title": "modern brush",
                "price": 344440,
                "status": "old",
                "discount": "30",
                "rating": 1
            }
        ]
    },
    {
        "heading": "Product2",
        "id": 2,
        "result": [
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/12.webp",
                "title": "modern brush",
                "price": 30,
                "status": "new",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/4.webp",
                "title": "modern brush",
                "price": 12,
                "status": "new",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/6.webp",
                "title": "modern brush",
                "price": 300,
                "status": "new",
                "discount": "30",
                "rating": 5
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/1.webp",
                "title": "modern brush",
                "price": 300000,
                "status": "new",
                "discount": "30",
                "rating": 4
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/5.webp",
                "title": "modern brush",
                "price": 344440,
                "status": "new",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/12.webp",
                "title": "modern brush",
                "price": 30,
                "status": "new",
                "discount": "30",
                "rating": 1
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/4.webp",
                "title": "modern brush",
                "price": 12,
                "status": "new",
                "discount": "30",
                "rating": 3
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/6.webp",
                "title": "modern brush",
                "price": 300,
                "status": "new",
                "discount": "30",
                "rating": 5
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/1.webp",
                "title": "modern brush",
                "price": 300000,
                "status": "new",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/5.webp",
                "title": "modern brush",
                "price": 344440,
                "status": "new",
                "discount": "30",
                "rating": 1
            }
        ]
    },
    {
        "heading": "Product3",
        "id": 3,
        "result": [
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            },
            {
                "image": "https://htmldemo.net/makali/makali/img/products/organic/13.webp",
                "title": "modern brush",
                "price": 30,
                "status": "Out of",
                "discount": "30",
                "rating": 2
            }

        ]
    }
]
const Ourproductcomp = ({multiplefullcomp}) => {
    const dispatch = useDispatch();
    const carouselseletd = useSelector((state) => state.Home1Reducer.carouselselected);
    const clicks = (clickdata) => {
        const data = multiplefullcomp.filter((ele) => ele.category_ids == clickdata)
        dispatch({ type: "setCarouselselected", payload: data })

    }
    useEffect(() => {
        dispatch({ type: "setCarouselselected", payload: [multiplefullcomp[0]] });
    }, []);
    return (
        <div>
            <div className={`main-ourproducts `}>
            {/* <div className='space-40'></div> */}
                {
                    multiplefullcomp &&
                    multiplefullcomp.length> 0 &&
                    multiplefullcomp.map(ele => {
                        return (
                            <div className='' >
                                <div onClick={() => clicks(ele.category_ids)}>
                                    <div className={`ourprodutscat ${carouselseletd && carouselseletd[0] && carouselseletd[0].category_ids === ele.category_ids ?  'primaryColor' :'secondaryColor primaryColorHover' }`}>
                                        {ele.carosal_heading}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {/* <div className='space-30'></div> */}
            <div className='space-30'></div>
            <SingleCarousel filterdata={carouselseletd} ourproduct={true} />
        </div>
    )
}
export default Ourproductcomp;