import React from "react";
const HeadingTitle = ({className,HeadingTitle,content,className1,titleclass,discriptionclass}) => {
    return (
        <div className="testimonials">
          <div className={`section-title text-${className} ${className1}`}>
                <h2 className={`Heading-title ${titleclass}`}>{HeadingTitle}</h2>
                <p className={`Heading-discription ${discriptionclass}`}>{content}</p>
                {/* <div className=""><img src="https://static.vecteezy.com/system/resources/thumbnails/028/866/689/small_2x/simple-dotted-line-shape-vector.jpg"></img> </div> */}
            </div>
        </div>
    )
}
export default HeadingTitle;