import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
const FourBanner = ({fourbannerdata}) => {
    const isMobile = useMediaQuery({ query: `(max-width: 756px)` });
    return (
        <Row>
            <Col lg={6} md={6} sm={12}>
                <div class="single-categorie">
                    <div class="cat-img">
                        <img src={fourbannerdata[0].card_image}></img>
                       {/* <div className={``}>Category</div> */}
                    </div>
                </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
                <Row>
                <Col lg={6} md={6} sm={12} className={isMobile ? "mt-3" : ""} >

                        <div class="single-categorie">
                            <div class="cat-img">
                            <img src={fourbannerdata[1].card_image}></img>

                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className={isMobile ? "mt-3" : ""} >

                        <div class="single-categorie">
                            <div class="cat-img">
                            <img src={fourbannerdata[2].card_image}></img>

                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} className='mt-3'>
                        <div class="single-categorie">
                            <div class="cat-img">
                            <img src={fourbannerdata[3].card_image}></img>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default FourBanner;