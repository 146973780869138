import Apis from "../../services/Apis";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

import { notify } from '../../components/common';
import { useSelector, useDispatch } from "react-redux";
const CartAction = (searchParams) => {
  const apidispatch = useDispatch()
  const { cartListService, add2cartService,add2wishlistService,customerreview,cancelOrderService,returnOrderService, changeCartQuantityService, saveRatingService,checkcoupanid, updateCartService, deletecartService } = Apis();
  // const navigate = useNavigate();

  const authStatus = useSelector(state => state.masterdata.authStatus);
  const addon = useSelector(state => state.productdetail.addon);
  const quantity = useSelector(state => state.productdetail.quantity);

  var cookies = new Cookies();

  const getCartList = () => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    if (cookies.get("jwt_token") == undefined) {
      var input_data = {
        "session_id": localStorage.getItem("sessionId") == null ? "0" : localStorage.getItem("sessionId")
      }
    }

    const response = await cartListService(input_data);

    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'cart_list',
          payload: response.data
        });
        dispatch({
          type: 'setcartDetaildup',
          payload: response.data
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      } else {
        dispatch({
          type: 'cart_list',
          payload: []
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      }
    }
  };

  const addToCart = (id, type) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    if (!authStatus) {
      const randomId = Math.floor(Math.random() * 100000000);
      if (localStorage.getItem("sessionId") === null) {
        localStorage.setItem('sessionId', randomId);
      }
    }

    if (searchParams.has("variant")) {
      var final_data = searchParams.get("variant");
    } else {
      var final_data = 0
    }
    const input_data = {
      "product_id": id,
      "quantity": quantity,
      "type": type,
      ...(authStatus == false && { "session_id": localStorage.getItem("sessionId") }),
      ...(final_data > 0 && { "variantid": final_data }),
      ...(addon.length > 0 && {
        "add_on": {
          "type": "addon",
          "products": addon
        }
      })
    }
    const response = await add2cartService(input_data);
    dispatch({
      type: 'setAddtocartpage',
      payload: response.data.message
    });
    if (response.data.status == "success") {
      apidispatch(getCartList());
      // setopenModal(false)
 
      // navigator.vibrate(300);
      notify('add2cart', response.data.message , true,'/cart')
    
      dispatch({
        type: 'setloader',
        payload: false
      });
  
      dispatch({
        type: 'setopenModal',
        payload: false
      });
     
    } else {
      // setLoaderstatus(false)
      notify('warning', response.data.message, true,'/cart')
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };

  const addToWishList = (id, type) => async dispatch => {
    if (searchParams.has("variant")) {
      var final_data = searchParams.get("variant");
    } else {
      var final_data = 0
    }
    dispatch({
      type: 'setLoaderstatus',
      payload: true
    });
    const input_data = {
      "product_id": id,
      "quantity": quantity,
      "type": type,
      ...(final_data > 0 && { "variantid": final_data }),
    }
    const response = await add2wishlistService(input_data);
    if (response.data.status == "success") {
      dispatch({
        type: 'setopenModal',
        payload: false
      });
      apidispatch(getCartList());
      dispatch({
        type: 'setLoaderstatus',
        payload: false
      });
      navigator.vibrate(300);
      notify('add2cart', response.data.message, true,'/wishlist')
    } else {
      dispatch({
        type: 'setLoaderstatus',
        payload: false
      });
      notify('warning', response.data.message, true,'/wishlist')
    }
  };


  const getCartListdup = (setproductLoaderstatus) => async dispatch => {
   

    if (cookies.get("jwt_token") == undefined) {
      var input_data = {
        "session_id": localStorage.getItem("sessionId") == null ? "0" : localStorage.getItem("sessionId")
      }
    }
    const response = await cartListService(input_data);
    if (response.data.status == "success") {
      // setproductLoaderstatus(false)
      dispatch({
        type: 'cart_list',
        payload: response.data
      });
    }
  };

  const addToCartProduct = (input_data, setproductLoaderstatus) => async dispatch => {
    const response = await add2cartService(input_data);
    dispatch({
      type: 'setAddtocartpage',
      payload: response.data.message
    });
    if (response.data.status == "success") {
     
      apidispatch(getCartListdup(setproductLoaderstatus))
    
      navigator.vibrate(300);
      notify('add2cart', response.data.message, true,'/cart')
      // navigate("/cart")
    } else {
      notify('warning', response.data.message, false,'')
    }
  };

  const changeCartQuantitydup = (input_data, setproductLoaderstatus) => async dispatch => {
    setproductLoaderstatus(true)
    const response = await changeCartQuantityService(input_data);
    if (response.data.status == "success") {
      apidispatch(getCartListdup())
      setproductLoaderstatus(false)
      navigator.vibrate(300);
      notify('add2cart', response.data.message, true)
    }
  };

  const checkcoupan = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await checkcoupanid(input_data);
    if (response.status == 200) {
      if (response.data.status == "success") {
        dispatch({
          type: 'setCoupanmsg',
          payload: response.data
        });
        dispatch({
          type: 'setdiscount',
          payload: response.data.discount
        });
      } else {
        dispatch({
          type: 'setCoupanmsg',
          payload: response.data
        });
        dispatch({
          type: 'setdiscount',
          payload: 0
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      }
    }
  };

  const changeCartQuantity = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await changeCartQuantityService(input_data);
    if (response.data.status == "success") {
      apidispatch(getCartList())
      navigator.vibrate(300);
      notify('add2cart', response.data.message, true)
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };

  const updateCart = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await updateCartService(input_data);
    if (response.data.status == "success") {
      apidispatch(getCartList());
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };

  const deleteCart = (input_data,type) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await deletecartService(input_data);
    if (response.data.status == "success") {
      apidispatch(getCartList());
      if(type == 'wishlist'){
      notify('warning', 'Removed from wishlist', false)
      }else{
        notify('warning', response.data.message, false)
      }
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };

  const saveRating = (input_data) => async dispatch => {
 
    // dispatch({
    //   type: 'setloader',
    //   payload: true
    // });
    const response = await saveRatingService(input_data);
    
    if (response.data.status == "success") {
      getCartList();
      // dispatch({
      //   type: 'setloader',
      //   payload: false
      // });
      navigator.vibrate(300);
      notify('warning', response.data.message, true)
    }
  };
  const cancelOrderlist = (input_data) => async dispatch => {
 
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await cancelOrderService(input_data);
  
    if (response.data.status == "success") {
      // alert("jhgj")
      // getCartList();
      dispatch({
        type: 'setloader',
        payload: false
      });
      navigator.vibrate(300);
      notify('warning', response.data.message, true)
    }
  };
  const returnOrderServicelist = (input_data) => async dispatch => {
 
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await returnOrderService(input_data);
  
    if (response.data.status == "success") {
      // alert("jhgj")
      // getCartList();
      dispatch({
        type: 'setloader',
        payload: false
      });
      navigator.vibrate(300);
      notify('warning', response.data.message, true)
    }
  };
  const customerreviewdata = (input_data) => async dispatch => {
 
    // dispatch({
    //   type: 'setloader',
    //   payload: true
    // });
    const response = await customerreview(input_data);
  
    if (response.data.status == "success") {
      // alert("jhgj")
      // getCartList();
      dispatch({
        type: 'setreviewdetails',
        payload: response.data.data
      });
      // navigator.vibrate(300);
      // notify('warning', response.data.message, true)
    }
  };
  return {
    getCartList: getCartList,
    addToCart: addToCart,
    cancelOrderlist:cancelOrderlist,
    addToCartProduct: addToCartProduct,
    changeCartQuantitydup: changeCartQuantitydup,
    changeCartQuantity: changeCartQuantity,
    checkcoupan: checkcoupan,
    updateCart: updateCart,
    deleteCart: deleteCart,
    returnOrderServicelist:returnOrderServicelist,
    saveRating:saveRating,
    customerreviewdata:customerreviewdata,
    addToWishList:addToWishList
  }
};

export default CartAction;
