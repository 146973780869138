import React, { useContext, useEffect, useState } from 'react';
import { BsXLg } from 'react-icons/bs';
import { StateContext } from '../../components/context/ManageStates';
import { CartList } from '../common';
import { useMediaQuery } from "react-responsive";
import { Form, Row, Button } from "react-bootstrap";
import validate from "../../validations/FormValidationRules";
import { PaymentMethodComp, PaymentRazarpay } from '../common';
import { useSelector, useDispatch } from 'react-redux';
import OrderAction from '../../redux/actions/OrderAction';

const CartPaymentMethod = ({ screentype, type }) => {
    const dispatch = useDispatch()
    const userData = useSelector(state => state.masterdata.userData);
    const billingerrors = useSelector(state => state.addressdata.billingerrors);
    const billingformValues = useSelector(state => state.addressdata.billingformValues);
    const errors = useSelector(state => state.addressdata.errors);
    const addressList = useSelector(state => state.addressdata.addressList);
    const razzer = useSelector(state => state.orderdetail.razzer);
    const formValues = useSelector(state => state.addressdata.formValues);

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    const [checked, setchecked] = useState(2);

    const { postplaceorder } = OrderAction()

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        dispatch({ type: 'setFormValues', payload: formValues })
    }, [formValues]);

    useEffect(() => {
        if (addressList != undefined && addressList.length > 0) {
            var listadd = addressList.filter(ele => ele.addresstype == 'Delivery')
            if (listadd.length > 0) {
                dispatch({ type: 'setFormValues', payload: addressList.filter(ele => ele.default == 1)[0] })
            }
            if (userData.hasOwnProperty("cust_type") && userData.cust_type == "2") {
                var billing_add = addressList.filter(ele => ele.addresstype == 'Billing')[0]
                if (billing_add != undefined) {
                    dispatch({
                        type: 'setbillingFormValues', payload: {
                            billingname: billing_add.name,
                            billingphone: billing_add.phone,
                            billingaddress: billing_add.address,
                            billingpincode: billing_add.pincode,
                            billingstate: billing_add.state,
                            addressId: "0",
                            addresstype: "Billing"
                        }
                    })
                }

            }
        }
    }, [addressList]);



    const handlePayment = async () => {
        var input_data = {
            payment_method: checked
        }
        dispatch(postplaceorder(input_data));
    }

    useEffect(() => {
        setIsSubmitting(false)
        if ((userData.hasOwnProperty("cust_type") && userData.cust_type == 2)) {
            if ((Object.keys(errors).length === 0 && Object.keys(billingerrors).length === 0) && isSubmitting) {
                // callback();
                handlePayment()
            }
        } else {
            if (Object.keys(errors).length === 0 && isSubmitting) {
                // callback();
                handlePayment()
            }
        }
    }, [errors]);


    const handleSubmit = (event) => {
        const sap = validate(formValues);
        if (Object.keys(sap).length > 0) {
            window.scrollTo(0, 0)
        }
        if (event) event.preventDefault();
        if (errors.hasOwnProperty('mailid')) {
            dispatch({
                type: 'setErrors',
                payload: {
                    ...errors,
                    ...validate(formValues)
                }
            })
        } else {
            dispatch({
                type: 'setErrors',
                payload: validate(formValues)
            })
        }
        dispatch({ type: 'setbillingErrors', payload: validate(billingformValues) })
        setIsSubmitting(true);
    };

    const Paymentmethod = () => {
        return (
            <div style={{ padding: "10px 10px 10px 15px" }}>
                <Form.Check
                    type="radio"
                    name="paymenttype"
                    className='mb-3'
                    value={2}
                    label="Credit/Debit Card/Net Banking"
                    onChange={(e) => setchecked(e.target.value)}
                    checked={checked == 2}
                    aria-label="radio 1"
                />
                <Form.Check
                    type="radio"
                    name="paymenttype"
                    value={1}
                    label="COD"
                    onChange={(e) => setchecked(e.target.value)}
                    checked={checked == 1}
                    aria-label="radio 1"
                />
            </div>
        )
    }

    return (
        <div className={`${screentype == 'checkout' ? "border-none" : ""} payment_method ${type != 'dropdown' ? 'mt-2 mb-3' : 'modal-sheet'}`}>
            {
                screentype == "checkout" &&
                <>
                    <CartList type={"checkout"} />
                </>
            }

            <div className={`${!isDesktoporMobile ? 'mt-4' : ''}`}>
                <PaymentMethodComp screentype={screentype} type={type} />
            </div>

            <div className={`${!isDesktoporMobile ? 'mt-4' : ''}`}>
                {
                    razzer.length > 1 &&
                    <div className='payment_header'>Payment Method</div>
                }

                <PaymentRazarpay />

            </div>

            {/* {
                screentype == "checkout" &&
                <>
                    <div className='mt-3'>
                        <CheckoutTabs id={3} title={"Payment Method"} info={<Paymentmethod type={7} />} />
                    </div>


                </>
            } */}



            {
                screentype == "checkout" && <div className='product-detail-buttongroup order-summary-btn mt-4' onClick={handleSubmit}>
                    <div className='product-detail-button primaryColor'>
                        Complete Payment
                    </div>
                </div>
            }

        </div>
    );
};

export default CartPaymentMethod;