import React, { useEffect } from "react";
import { FaStar, FaRegStar, FaPlus } from "react-icons/fa6";
import logo from '../../images/bg_image.webp';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { IoCloseCircleSharp } from "react-icons/io5";
import CartAction from '../../redux/actions/CartAction';

const CardProduct = ({ discount, image, wishlist, className1, title, slug, price, status, rating, productid, mrp }) => {
    const { addToCartProduct, deleteCart } = CartAction();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const addtocartpage = useSelector(state => state.cartdetail.addtocartpage);
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const renderStars = () => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<FaStar key={i} />);
            } else {
                stars.push(<FaRegStar key={i} />);
            }
        }
        return stars;
    };

    const handleAddToCart = (id) => {
        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (localStorage.getItem("sessionId") === null) {
                localStorage.setItem('sessionId', randomId);
            }
        }
        const inputData = {
            "product_id": id,
            "quantity": 1,
            "type": 'product',
            ...(authStatus === false && { "session_id": localStorage.getItem("sessionId") })
        };

        dispatch(addToCartProduct(inputData));


    };
    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteCart(input_data, 'wishlist'))
    }
    // useEffect(() => {
    if (addtocartpage === "Added to cart") {
        // alert()
        navigate("/cart");
        dispatch({
            type: 'setAddtocartpage',
            payload: ""
        });
        dispatch({
            type:"setcartpagerediredct",
            payload:true
        })
    } else if (addtocartpage) {
        navigate(1);
      
    }
    // }, [addtocartpage]);


    return (
        <div className="single-makal-product">
            <div className="wishlist-card-wrong">
                <NavLink to={`/product/${slug}`} className="text-decoration-none">
                    <div className="pro-img image_aspect_ratio">
                     
                            <img  src={image} onError={e => e.currentTarget.src = logo} alt={title} />
                     
                     {
                        discount > 0 &&
                        <div className="productcard-status-discount">
                        {/* {status && <div className="sticker-new secondaryColor">{status}</div>} */}
                        {<div className="sticker-sale primaryColor">{discount}%</div>}
                    </div>
                     }
                       
                    </div>
                </NavLink>
                {
                    wishlist &&
                    <IoCloseCircleSharp className="whishlist-wrong-button" onClick={() => clicked('cart', productid)} />
                }
            </div>
            <div className={`pro-content`}>
            <NavLink to={`/product/${slug}`} className="text-decoration-none">
                <h6 className={`pro-title primaryColorHovercolor ${className1}`}>{title}</h6>
                </NavLink>
                <div>
                    <p className="price-category-list">
                        <span className={`price ${className1}`}>{localStorage.getItem("currency") + price}</span>
                        {mrp !== price && <span className="price cross-price">{localStorage.getItem("currency") + mrp}</span>}
                    </p>
                </div>
                <div className="pro-actions"> 
                    {
                        wishlist ? (
                            <div className="actions-primary">
                                <div className={`add-to-cart primaryColorHovercolor ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist" onClick={() => clicked('move', productid)}>
                                    <FaPlus className="addcarticons" /> Move To Cart
                                </div>
                            </div>
                        ) : (
                            <div className="actions-primary">
                                <div className={`add-to-cart primaryColorHovercolor ${className1}`} data-toggle="tooltip" data-original-title="Add to Cart" onClick={() => handleAddToCart(productid)}>
                                    <FaPlus className="addcarticons" /> Add To Cart
                                </div>
                            </div>
                        )
                    }


                    <div className="actions-secondary">
                        {rating && rating.length > 0 && <div className="rating primaryColorcolor">{renderStars()}</div>}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default CardProduct;
