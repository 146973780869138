import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { InputBox } from '../common'
import ProductAction from '../../redux/actions/ProductAction';
import  validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
const Contactformaddress = () => {
  const dispatch = useDispatch()
  const { saveContactFormdatalist } = ProductAction();
  const loaderstatus1 = useSelector(state => state.masterdata.loaderstatus1);
   const [messages,setMessages]=useState();
  
  const [formValues, setFormValues] = React.useState(

    {
      "name": "",
      "phone": "",
      "email": "",
      "subject": "",
      "message":messages,
     
    }

  );
  const { values, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
    handleNext,
    validate,
    formValues
  );


  function handleNext() {
    dispatch(saveContactFormdatalist( { ...values, message: messages , setErrors}))
  }

  return (
    <div>
        <Container>
      <Row className='contactform-row-div'>
        <Col lg={12} md={12} sm={12}>
          <Row className='contactformaddress-main-div'>
            <div class="ot-heading">
              <div className='heading-contact'>  <span class="line-side primaryColor"></span><span class="line-content"> TO GET STARTED</span></div>

              <h3 class="main-head">Ready to<span class=" primaryColorcolor"> Get Started?</span></h3>
            </div>
            <p>Please, fulfill the form to get a consultation.</p>
            <Col lg={6}>
              <InputBox label={"Name"} id={"name"} handleChange={handleChange} errors={errors} />
            </Col>
            <Col lg={6}>
              <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
            </Col>
            <Col lg={6}>
              <InputBox label={"Email"} id={"email"} handleChange={handleChange} errors={errors} />
            </Col>
            <Col lg={6}>
              <InputBox label={"Subject"} id={"subject"} handleChange={handleChange} errors={errors} />
            </Col>
            {/* <Col lg={12}>
              <InputBox label={"Message"} id={"Message"} handleChange={handleChange} errors={errors} />
            </Col> */}
            <label className='contactformaddress-message primaryColorcolor'>Message</label>
            <textarea  className='contactaddress-textarea'
              id="message"
           
              onChange={(e) => setMessages(e.target.value)}
              value={messages}/>

        
            <div style={{ position: "relative",pointerEvents:loaderstatus1 && 'none' }} className={`product-detail-buttongroup login-register-btn `} onClick={handleSubmit}>
              <div className={`product-detail-button primaryColor secondaryColorHover`} >
                Submit
              </div>
              {
                loaderstatus1 &&
                <div style={{ position: 'absolute', top: '50%', left: '85%', transform: 'translate(-50%, -50%)' }}>
                  {/* <ClipLoader className="cart-btn-loader" loading={true} size={50} /> */}
                  <Spinner animation="border" role="status" className='custom-spinners1' />
                </div>
              }

            </div>

            {/* <div className='about-main'>
      <div className="about-button">READ MORE</div>
      </div> */}
          </Row>
        </Col>


      </Row>
      </Container>
    </div>
  )
}
export default Contactformaddress;